/* eslint-disable */
<template>
  <div
    class="row"
    v-if="
      typeof getActiveUserData.user !== 'undefined' &&
        Object.keys(getActiveUserData.user).length
    "
  >
    <admin-header-header-vue
      v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
    ></admin-header-header-vue>
    <projectcoordinators-header
      v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
    >
    </projectcoordinators-header>
    <salesteamlead-header-vue
      v-else-if=" (getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
    ></salesteamlead-header-vue>
    <marketingmanager-header-vue
      v-else-if="getActiveUserData.userRoles == 'marketingmanager'"
    ></marketingmanager-header-vue>
    <marketingperson-header-vue
      v-else-if="getActiveUserData.userRoles == 'marketingperson'"
    ></marketingperson-header-vue>
    <salesperson-header-vue
      v-else-if="getActiveUserData.userRoles == 'salesperson'"
    ></salesperson-header-vue>
    <devops-header-vue
      v-else-if="getActiveUserData.userRoles == 'devops'"
    ></devops-header-vue>
    <client-header-vue
      v-else-if="getActiveUserData.userRoles == 'client'"
    ></client-header-vue>
    <default-header-vue v-else></default-header-vue>
  </div>
</template>
<script>
import admin_header from "./admin_header";
import salesteamlead_header from "./salesteamlead_header";
import marketingmanager_header from "./marketing_manager_header";
import marketingperson_header from "./ marketing-person-header";
import salesperson_header from "./salesperson_header";
import client_header from "./client_header";
import devops_header from "./header-devops";
import projectcoordinators_header from "./projectcoordinators_header.vue";
import default_header from "./default_header";

export default {
  data() {
    return {
      pre: window.pre,
      homepre: window.pre
    };
  },
  components: {
    "admin-header-header-vue": admin_header,
    "salesteamlead-header-vue": salesteamlead_header,
    "marketingmanager-header-vue": marketingmanager_header,
    "marketingperson-header-vue": marketingperson_header,
    "salesperson-header-vue": salesperson_header,
    "client-header-vue": client_header,
    "devops-header-vue": devops_header,
    "projectcoordinators-header" : projectcoordinators_header,
    "default-header-vue": default_header
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  }
};
</script>
