/* eslint-disable */
<template>
  <div class="record-not-found">
    <p class="icon-para"><strong>No Record Found!</strong> <i class="fas fa-ban"></i></p>
    <!-- <p>No Record Found!</p> -->
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
.record-not-found {
  text-align: center;
  // padding-top: 20px;
  p.icon-para {
    // color: red;
    // font-size: 40px;
    // margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
  }
}
</style>
